import React from 'react';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

//使ってない

const TalkResult = (props:Props)=> {
    return (
		<div className="sysTalk">
			<span className="type">決着</span>
			<span className="detail">決着がついた</span>
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
	);
}

export default TalkResult;
