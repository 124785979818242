import React,{useState} from 'react';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
//import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import ModalHeader from './ModalHeader';
import {Tools} from './Tools';
import HelpIcon from '@material-ui/icons/Help';
import Fab from '@material-ui/core/Fab';

const Help = ()=> {
	const [isOpen,setIsOpen]=useState(false);
    const open = ()=>{
		setIsOpen(true);
    }
    const close = ()=>{
		setIsOpen(false);
    }
    return (
		<React.Fragment>
			<div style={{position:"fixed",left:"0.5em",bottom:"0.5em"}}>
				<Tooltip title="遊び方">
					<Fab color="primary" size="small" onClick={open}><HelpIcon/></Fab>
				</Tooltip>
			</div>
			<Modal
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				open={isOpen}
				onClose={close}
			>
				<div id="faces_input" className="modal">
					<ModalHeader close={close}/>
					<div className="modalRoot" style={Tools.getModalRootStyle()}>
						<Paper className="paper modalPaper">
							<h2>ヘルプ・遊び方</h2>
							<div className="help">
								<h3>どういうゲーム？
								</h3>
								<div className="text">
									<p>日直と同じ場所で寝るとアウト。その日の日直と違う寝床を選びつつ、最後まで生き残るのがゲームの目的です。参加者同士で会話をしながらお互いの思惑を探ってください。</p>
								</div>
								<h3>日直とタロットカード</h3>
								<div className="text">
									<p>日直は朝一番に発表されます。ただし名指しで発表されるわけではなく、その人が持っているタロットカード（正体）が発表されます。</p>
									<div className="hint">
										<p>・誰がどのタロットカードを持っているかは本人にしか分かりませんので、誰が日直になったかも本人にしか分かりません。</p>
										<p>・自分のタロットカードはプロローグが終了するまで分かりません。</p>
										<p>・日直の順番はランダムですが、生き残っている参加者全員が1回ずつ日直をやるまでは誰かに2回目が回ることはありません。2回目以降も同様です。</p>
									</div>
								</div>
								<h3>寝床の選択</h3>
								<div className="text">
									<p>日直が発表されたら、その日が終わるまでに寝床を選択してください。</p>
									<div className="hint">
										<p>・寝床の選択は一日一回だけです。選択後に変更することはできません。</p>
										<p>・寝床の選択をしたことはほかの参加者にも知らされますが、どの寝床を選んだかは本人にしか分かりません。</p>
										<p>・寝床の選択をしなかった場合は、自動的に「ホール」を選んだことになります。</p>
									</div>
								</div>
								<h3>寝床の定員</h3>
								<div className="text">
									<p>ホール以外の寝床は定員2名で先着順です。</p>
									<div className="hint">
										<p>・定員を超えているかどうかは選択した時点では分かりません。</p>
										<p>・定員を超えた場合はホールで寝ることになります。</p>
										<p>・ホールには定員がありません。</p>
									</div>
								</div>
								<h3>寝床の立入禁止</h3>
								<div className="text">
									<p>誰にも選ばれなかった寝床は立入禁止となり、以後選択できなくなります。</p>
									<p>ホールは誰もいなくなっても立入禁止になりません。</p>
								</div>
								<h3>ゴースト</h3>
								<div className="text">
									<p>日直と同じ場所で寝てしまった参加者は、以後ゴーストになります。</p>
									<div className="hint">
										<p>・ゴーストはそれまでと同様に会話を眺めることができますが、ゴーストの言っていることはゴースト以外の参加者には届きません。</p>
										<p>・ゴーストになったら寝床の選択は不要です。</p>
									</div>
								</div>
								<h3>鳩罠</h3>
								<div className="text">
									<p>ゴーストが３人以上になると「鳩罠」が使用できるようになります。鳩罠を仕掛けられた場所で寝た参加者は、そこに日直がいなくてもアウトになります。</p>
									<div className="hint">
										<p>・鳩罠を仕掛ける場所はゴーストたちの投票で決まります。最多得票かつ全投票数の過半数以上で決定です。無投票は票数に含みません。</p>
										<p>・票が割れてどれも過半数以上にならない場合は鳩罠は使用されません。</p>
										<p>・鳩罠はホールにも仕掛けることができます。</p>
									</div>
								</div>								
								<h3>勝利条件</h3>
								<div className="text">
									<p>・ゴーストにならずに生き残っている参加者が１人だけになったら、その参加者の勝利となります。</p>
									<p>・全員がゴーストになったら、ゴーストたちの勝利となります。</p>
								</div>
								<h3>ドロー</h3>
								<div className="text">
									<p>
										以下の２つの条件を満たしたら引き分けです。（進行役の姉妹が痺れを切らすため。）<br/>
									</p>
									<ul>
										<li>２人以上の参加者がゴーストにならずに生き残っている。</li>
										<li>選択可能な寝床が２つ（ホールとどこか１つ）しかない。</li>
									</ul>
								</div>
								<h3>エピローグ</h3>
								<div className="text">
									<p>勝敗の決着後、これまでのすべての発言と行動が公開されます。</p>
									<div className="hint">
										<p>・自分にしか見えなかった発言なども公開されますので、見られて困るような内容は書かないように気をつけてください。</p>
									</div>
								</div>
								<h3>ハコブネの作成とインターバル</h3>
								<div className="text">
									<p>ゲーム（ハコブネ）の定員は6～8人です。参加したいハコブネがない場合は「ハコブネ作成」でハコブネを追加して下さい。</p>
									<div className="hint">
										<p>・ハコブネ作成では進行ペースを「10分」「30分」「24時間」から選ぶことができます。これはゲーム内での1日の長さです。例えば「10分」を選ぶと寝床の選択を10分に1回することになり、かなり忙しい進行になります。</p>
										<p>・最初の朝までに参加者が必要最低人数（6人）に達しなかったハコブネは強制終了になります。この場合はエピローグはありません。</p>
									</div>
								</div>
								<h3>著作権等・注意事項</h3>
								<div className="text">
									<p>このコンテンツの著作権はレトロインクが有します。ただし、キャラクターのイラストの著作権はナカニシミエが有します。</p>
									<p>レトロインクは、プレイヤーがこのコンテンツに入力したテキストを、このコンテンツ内において自由に使用する権利を有します。</p>
									<p>このコンテンツは動作確認および仕様検討のためのパイロット版です。予告なく終了する場合もあります。</p>
								</div>
								<div className="history">
									<ul>
										<li>2020.06.27 公開</li>
									</ul>
								</div>
							</div>
						</Paper>
					</div>
				</div>
			</Modal>
		</React.Fragment>
    )
}

export default Help;