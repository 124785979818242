import React,{useMemo} from 'react';
//import MultiLines from './MultiLines';
import ImgFace from './ImgFace';
import {Inc,Room,Talk,PrivateData} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
	data:PrivateData,
}

const TalkMorning = (props:Props)=> {
	const text = useMemo(()=>{
		if( props.talk.Day===0 ){
			let auroraId = parseInt(Tools.toDate(props.talk.Date).substr(-2));
			if( props.talk.Message==="1" ){
				auroraId = 0;
			}
			const auroras = ["私、マフをいただきましたの。","えへん、このミルクは中国猫にいただいたものなのです。","クローバーの茎を集めて下さいませんか？","昨晩の流星群にチップを払っていただけましたの？","あなたは共食いをする動物なのかしら？","飛行船が来たかどうかちょっと見てきてくださいな！","狐の集まる庭にしたら素敵じゃない？","叔父様のネクタイピンをこっそり集めるのが趣味ですわ。","クルトンをあと一つだけ下さいな。","屋根裏にまたカンガルーがいらっしゃってるんじゃなくて？"]
			const aurora = auroras[ auroraId % auroras.length ];
			return (
				<React.Fragment>
					<div className="talk talkPublic">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
						<div className="text">
							<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
							<div>
								以上です…<br/>
								では、順番にトージョー手続きをお願いいたします…<br/>
							</div>
						</div>
					</div>
					<div className="talk talkPublic">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
						<div className="text">
							<h3>{props.room.Faces[Inc.numFaces+1].Name}</h3>
							<div>「{aurora}」</div>
						</div>
					</div>
					<div className="talk talkPublic">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
						<div className="text">
							<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
							<div>
								ハコブネへようこそ、残り物の皆様…<br/>
								まずは、{props.room.Faces[Inc.numFaces+1].Name}から皆様へのご挨拶です。<br/>
							</div>
						</div>
					</div>
					<div className="sysTalk">
						<span className="type">はじまり</span>
						<span className="detail">「{props.room.Title}」にハコブネが到着しました</span>
						<span className="time">{Tools.toTime(props.talk.Date)}</span>
					</div>
				</React.Fragment>
			)
		}

		if( props.room.Day===props.talk.Day && props.talk.Day>1 && props.room.Status!==Inc.ROOM_STATUS_INSERVICE ){
			return (
				<React.Fragment>
					<div className="talk talkPublic">
						<div className="serial">
							<span className="date">{Tools.toDate(props.talk.Date)}</span>
						</div>
						<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
						<div className="text">
							<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
							<div>
								すべての発言と行動が公開されました…<br/>
								残された時間を有意義にご利用ください…<br/>
							</div>
						</div>
					</div>
					<div className="sysTalk">
						<span className="type">エピローグ</span>
						<span className="detail">最後の朝になりました</span>
						<span className="time">{Tools.toTime(props.talk.Date)}</span>
					</div>
				</React.Fragment>
			)
		}
	
		let auroraId = props.talk.Message.length+1;
		const auroras = ["そんなところで服を脱ぐものではありませんわ。","まあ、かわいらしい足音ですこと！","砂場で砂鉄を袋いっぱいに集めましょう。","指輪に私の名前を彫ったの。忘れないように。","バイオリンを買ってその中に手紙を隠すのよ。","ねえ、大きな蛙を見たわ。馬車よりずっと大きいのよ。","ふむ、ミントは電気なのかしら。","シーッ！ミーガンは朝になれば帰ってきますからね。","あなた！ポップコーンをお売りになったらどうかしら。","台所に私がいないかどうか見てきてくださる？気づかれちゃダメよ。"]
		const aurora = auroras[ auroraId % auroras.length ];

		return (
			<React.Fragment>
				<div className="sysTalk">
					<span className="type">ハサミ</span>
					{props.data.ItemId === String(props.talk.CharaId) ? (
					<span className="detail">仕立て屋のハサミがあなたに手渡されました。</span>
					):(
					<span className="detail">仕立て屋のハサミが「{Inc.itemNames[props.talk.CharaId-1]}」に手渡されました。「{Inc.itemNames[props.talk.CharaId-1]}」は今晩同じ寝床で眠る全員を消すことができます。</span>
					)}
					<span className="time">{Tools.toTime(props.talk.Date)}</span>
				</div>
				<div className="talk talkPublic">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
					<div className="text">
						<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
						<div>
							以上です…<br/>
							仕立て屋のハサミを「<span className="item">{Inc.itemNames[props.talk.CharaId-1]}</span>」に委ねます…<br/>
							<span className="item">{Inc.itemNames[props.talk.CharaId-1]}</span>、貴方が今日の日直です…<br/>
						</div>
					</div>
				</div>
				<div className="talk talkPublic">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
					<div className="text">
						<h3>{props.room.Faces[Inc.numFaces+1].Name}</h3>
						<div>「{aurora}」</div>
					</div>
				</div>
				<div className="talk talkPublic">
					<div className="serial">
						<span className="date">{Tools.toDate(props.talk.Date)}</span>
					</div>
					<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
					<div className="text">
						<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
						<div>
							おはようございます…<br/>
							早速ですが皆様にお伝えしなくてはならないことがあります…<br/>
						</div>
					</div>
				</div>
				<div className="sysTalk">
					<span className="type">新しい朝</span>
					<span className="detail">「{props.room.Title}」に{props.talk.Day}日目の朝が来ました。</span>
					<span className="time">{Tools.toTime(props.talk.Date)}</span>
				</div>
			</React.Fragment>
		)
	},[props.talk.Day]);

    return (
		<React.Fragment>
			{text}
		</React.Fragment>
	);
}

export default TalkMorning;
