import React from 'react';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkDisabled = (props:Props)=> {
    return (
		<div className="sysTalk">
			<span className="type">立入禁止</span>
			<span className="detail">{Inc.placeNames[props.talk.CharaId]}はイバラに埋もれてしまいました</span>
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
	);
}

export default TalkDisabled;
