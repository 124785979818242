import React from 'react';
//import useStyles from './Styles';
//import loading from './loading.svg';
import "./TinyLoading.scss";

interface Props {
	isLoading:boolean,
}

const TinyLoading:React.FC<Props> = (props)=> {
	return (
		<div id="tiny_loading" className="tiny_loading">
			{props.isLoading &&
			<React.Fragment>
				<div className="circle1"></div>
				<div className="circle2"></div>
				<div className="circle3"></div>			
			</React.Fragment>
			}
		</div>
    );
}

export default TinyLoading;
