import React,{useEffect,useState,useRef,useCallback} from 'react';
import {Link,Redirect} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FacesInput from './FacesInput';
import RoomInput from './RoomInput';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
//import store from './store';
import Toast from './Toast';
import TopBar from "./TopBar";

import {UserContainer} from './UserContainer';
import {Inc,Info,CharaSet,Room} from './Inc';
import Ajax from './Ajax';
import store from './store';

interface Props {
    
}

const MyCharaSets = (props:Props)=> {
    const [states,setStates] = useState({
		editCharaSetIndex: -1,
		myCharaSets: [] as CharaSet[],
		isLoading:true,
		isFacesInput:false,
	})
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();

	const closeToast = ()=>{
		setToast("");
	}
    const openFacesInput = async ()=>{
		setStates({...states,editCharaSetIndex:-1,isFacesInput:true});
	}
    const closeFacesInput = async ()=>{
		setStates({...states,isFacesInput:false});
	}
    const load = async ()=>{
    	if(!userState.user){
    		return;
    	}
    	try {
    		setStates({...states,isLoading: true});
    		const sets = await store.getMyCharaSets(userState.user.uid);
			setStates({...states,myCharaSets:sets,isFacesInput:false,isLoading: false});
		} catch(error){
			console.log("load",error);
		}

	}
	const updateCharaSet = async (title:string,permission:string,names:string[])=>{
		if(!userState.user){
			return;
		}
		let id = "";
		if(states.editCharaSetIndex>-1){
			id = states.myCharaSets[states.editCharaSetIndex].Id;
		}
		try {
			setStates({...states,isLoading: true});
			const res = await Ajax.updateFace(id,title,permission,names,userState.user.uid);
			//console.log(res);
			await load();
			//setStates({...states,isFacesInput:false,isLoading: false});

		} catch(error) {

		}
	}
	const deleteCharaSet = async ()=>{
		if(!userState.user){
			return;
		}
		if(states.editCharaSetIndex==-1){
			return;
		}
		const id = states.myCharaSets[states.editCharaSetIndex].Id;
		try {
			setStates({...states,isLoading: true});
			const res = await Ajax.deleteFace(id,userState.user.uid);
			//console.log(res);
			await load();
			//setStates({...states,isFacesInput:false,isLoading: false});

		} catch(error) {

		}
	}

	const editCharaSet = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.index ){
            return;
        }
        const index = parseInt(event.currentTarget.dataset.index);
		setStates({...states,editCharaSetIndex:index,isFacesInput:true});
	}


	const targetCharaSet = useCallback(()=>{
		if( states.editCharaSetIndex===-1 ){
			return Inc.defaultCharaSet();
		} else {
			return states.myCharaSets[states.editCharaSetIndex];
		}
	},[states.editCharaSetIndex,states.myCharaSets]);

    useEffect( ()=>{
		const start = async ()=>{
			await load();
		}
        if( userState.ready ){
			start()
		}
	},[userState.ready,userState.user]);

    if( !userState.ready ){
        return (null);
    }

    return (
		<React.Fragment>
		<TopBar/>
        <div id="main" className="mediumRoot">
			<Paper className="paper">
				<div className="talk">
					<Button variant="contained" color="primary" onClick={openFacesInput}>キャラセット作成</Button>
				</div>
				{states.myCharaSets.length>0 &&
				<div>
					<h2>キャラセット</h2>
					<List>
	      			{states.myCharaSets.map((charaSet:CharaSet,index:number)=>{
	      				const key = "mycharaset"+index;
	      				return (
	      					<ListItem key={key} data-index={index} onClick={editCharaSet} button>
	      						<ListItemText primary={charaSet.Title}/>
	      					</ListItem>
	      				)
	      			})}

	      			</List>
      			</div>
      			}
			</Paper>
            <ClimbUp/>
            <Loading open={states.isLoading}/>
			<Toast close={closeToast} mes={toast}/>
			<FacesInput isOpen={states.isFacesInput} charaSet={targetCharaSet()} update={updateCharaSet} delete={deleteCharaSet} close={closeFacesInput}/>
        </div>
		</React.Fragment>
    );
}

export default MyCharaSets;