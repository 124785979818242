import {db} from './firebase';
import {CharaSet,Room} from './Inc';

export default class store {
	static SonyaCharaSets = process.env.REACT_APP_RUNMODE==="PROD" ? "SonyaCharaSets" : "SonyaCharaSetsDev";
	static SonyaRooms = process.env.REACT_APP_RUNMODE==="PROD" ? "SonyaRooms" : "SonyaRoomsDev";
	static getMyCharaSets = async (userId:string)=>{
		//console.log("store","getMyCharaSets");
		try {
			const ref = await db.collection(store.SonyaCharaSets).where("Deleted","==",false).where("UserId","==",userId).orderBy('CreatedAt','desc').limit(10).get();
			let sets:CharaSet[] = [];
			ref.forEach((doc) => {
				sets.push( doc.data() as CharaSet );
			});
			//console.log(sets);
			return sets;
		} catch(error) {
			throw error;
		}
	}

	static isSameCharaset = async (title:string,code:string)=>{
		try {
			const ref = await db.collection(store.SonyaCharaSets).where("Deleted","==",false).where("Title","==",title).orderBy('CreatedAt','desc').limit(2).get();
			let flag = false;
			ref.forEach((doc) => {
				if( doc.id !== code ){
					//console.log("isSame",doc.id,":",code);
					flag = true;
				}
			});
			return flag;
		} catch(error) {
			console.log("isSame",error);
			throw error;
		}
	}

	static getPublicCharaSets = async ()=>{
		//console.log("store","getPublucCharaSets");
		try {
			//console.log("public")
			const ref = await db.collection(store.SonyaCharaSets).where("Deleted","==",false).where("Permission","==","2").orderBy('CreatedAt','desc').limit(100).get();
			//console.log("public","get");
			let sets:CharaSet[] = [];
			ref.forEach((doc) => {
				sets.push( doc.data() as CharaSet );
			});
			//console.log(sets);
			return sets;
		} catch(error) {
			console.log("error",error);
			throw error;
		}
	}

	static getListingRooms = async ()=>{
		//console.log("store","getListingRooms");
		try {
			//console.log("public")
			const ref = await db.collection(store.SonyaRooms).where("Deleted","==",false).where("Listing","==",true).orderBy('CreatedAt','desc').limit(100).get();
			//const ref = await db.collection("SonyaRooms").where("Deleted","==",false).orderBy('CreatedAt','desc').limit(10).get();
			//console.log("public","get");
			let sets:Room[] = [];
			ref.forEach((doc) => {
				sets.push( doc.data() as Room );
			});
			//console.log(sets);
			return sets;
		} catch(error) {
			console.log("error",error);
			throw error;
		}
	}

	static getAllRooms = async ()=>{
		//console.log("store","getAllRooms");
		try {
			//console.log("public")
			const ref = await db.collection(store.SonyaRooms).where("Deleted","==",false).orderBy('CreatedAt','desc').limit(10).get();
			//console.log("public","get");
			let sets:Room[] = [];
			ref.forEach((doc) => {
				sets.push( doc.data() as Room );
			});
			//console.log(sets);
			return sets;
		} catch(error) {
			console.log("error",error);
			throw error;
		}
	}

	// promise/async/await
	static getRoom = async (id:string)=> {
		//console.log("store","getRoom");
		try {
			const doc = await db.collection(store.SonyaRooms).doc(id).get();
			if( doc.exists ){
				const room = doc.data() as Room;
				return room;
			}
			const error = new Error();
			error.message = "notFound";
			throw error;
		} catch(error) {
			throw error;
		}
	}

	static getUniqueId(myStrong?: number): string {
		let strong = 10000;
		if (myStrong) strong = myStrong;
		return (
			("0000" + Math.floor(strong * Math.random()).toString(16)).slice(-4) + ("00000000000" + new Date().getTime().toString(16)).slice(-11)
		);
	}

}