import React from 'react';
import {Inc,Room} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	limit:string,
}

const RoomStatus = (props:Props)=> {
	const limit = props.limit;
    return (
		<div className="roomStatus">
			<div className="title">{props.room.Title}</div>
			<div className="status">
			{props.room.Status===Inc.ROOM_STATUS_INSERVICE &&
			<span>{props.room.Day}日目</span>
			}
			{props.room.Status===Inc.ROOM_STATUS_WAITING &&
			<span>プロローグ</span>
			}
			{props.room.Status===Inc.ROOM_STATUS_EPILOGUE &&
			<span>エピローグ</span>
			}
			{props.room.Status===Inc.ROOM_STATUS_CANCELED &&
			<span>定員割れ終了</span>
			}
			{props.room.Status===Inc.ROOM_STATUS_CLOSED &&
			<span>終了</span>
			}
			</div>
			{props.limit && props.limit !== "" &&
			<div className="next">
				<span>Next</span>{Tools.toSimpleDate(props.limit)}
			</div>
			}
		</div>
	);
}

export default RoomStatus;
