import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
//import CloseIcon from '@material-ui/icons/Close';

interface Props {
    close(): void,
    mes: string,
}

const Toast = (props:Props)=> {
    let isOpen = false;
    if( props.mes && props.mes!=="" ){
        isOpen = true;
    }
    return (
        <div id="toast">
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={isOpen}
				autoHideDuration={3000}
				onClose={props.close}
				ContentProps={{
					'aria-describedby': 'message-id',
				}}
				message={<span id="message-id">{props.mes}</span>}
				action={[
				<IconButton
					key="closeToast"
					aria-label="CloseToast"
					color="inherit"
					onClick={props.close}
				>
					
				</IconButton>,
				]}
			/>
        </div>
    )
}

export default Toast;