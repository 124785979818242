import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import {Theme} from './Theme';
//import {Tools} from './Tools';

interface Props {
    close?():void,
}

const ModalHeader = (props:Props)=> {
    //const size = Tools.getModalSize();
	//size.width
	const close = ()=>{
		if( props.close ){
			props.close();
		}
	}
    return (
        <div id="modal_header" style={{width:"100%",boxSizing:"border-box",color:"white",position:"fixed",top:"0",left:"0",zIndex:1000,backgroundColor:Theme.palette.primary.main}}>
			{props.close ? (
            <IconButton aria-label="close" style={{padding:"0.25em",color:Theme.palette.primary.contrastText}} onClick={close}><CancelIcon/></IconButton>
			):(
			<div>　</div>
			)}
        </div>
    )
}

export default ModalHeader;