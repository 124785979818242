import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import MultiLines from './MultiLines';
import {Tools} from './Tools';
//import useStyles from './Styles';

interface Props {
    mes: string,
    yes():void,
    no():void,
    isOpen:boolean,
}

const Yesno = (props:Props)=> {
    //const classes = useStyles();

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.no}
        >
            <div id="faces_input" className="modal modalSmall">
                <div className="modalRoot" style={Tools.getModalRootStyle()}>
                    <Paper className="paper modalPaper modalPaperNoHeader">
                        <div style={{marginBottom:"2em"}}>
                            <MultiLines text={props.mes}/>
                        </div>
                        <div style={{position:"absolute",bottom:"1em",left:"0",padding:"0 1em",boxSizing:"border-box",width:"100%"}}>
                        <Button variant="contained" color="default" style={{width:"40%"}} onClick={props.no}>いいえ</Button>
                        <Button variant="contained" color="primary" style={{width:"60%"}} onClick={props.yes}>はい</Button>
                        </div>
                    </Paper>
                </div>
            </div>
        </Modal>
    );
}

export default Yesno;
