import React from 'react';
import Grid from '@material-ui/core/Grid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ImgFace from './ImgFace';
import {Inc,Room,PrivateData} from './Inc';

interface Props {
	room:Room,
	data:PrivateData,
}

const Chara = (props:Props)=> {
	let place = "セーフルーム";
	if( props.data.History[props.data.CheckedDay].PlaceId!=="S" ){
		place = Inc.placeNames[parseInt(props.data.History[props.data.CheckedDay].PlaceId)];
	}
    return (
		<div id="chara" className="myChara">
			<h2>プレイヤー</h2>
			<div className="face">
				<ImgFace code={props.room.CharaSetId} charaId={props.data.CharaId}/>
			</div>
			{props.data.History[props.data.CheckedDay].VanishedDay>0 &&
			<div className="vanished">
				{props.data.History[props.data.CheckedDay].VanishedDay}日目に消された
			</div>
			}
			{props.data.History[props.data.CheckedDay].Blessed &&
			<div className="blessed">
				<FontAwesomeIcon icon="cut"/> 日直
			</div>
			}
			<Grid container spacing={0} style={{marginTop:"0.5em"}}>
				<Grid item xs={3}>名前</Grid>
				<Grid item xs={9}>{props.room.Faces[props.data.CharaId-1].Name}</Grid>
			{props.data.ItemId!=="0" &&
			<React.Fragment>
				<Grid item xs={3}>正体</Grid>
				<Grid item xs={9}>
					{Inc.itemNames[parseInt(props.data.ItemId)-1]}
				</Grid>
			</React.Fragment>
			}
			{props.room.Day>1 && props.data.History[props.data.CheckedDay].VanishedDay===0 &&
			<React.Fragment>
				<Grid item xs={3}>昨夜</Grid>
				<Grid item xs={9}>
					{place}
				</Grid>
			</React.Fragment>
			}
			</Grid>
        </div>
    );
}

export default Chara;
