import React from 'react';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import ModalHeader from './ModalHeader';
//import useStyles from './Styles';
import Title from './Title';
import {Tools} from './Tools';

interface Props {
	isOpen:boolean,
	close?():void,
}

const NoResponse:React.FC<Props> = (props)=> {
    //const classes = useStyles();
    if( !props.isOpen ){
        return (null);
    }

    const close = ()=>{
		if( props.close ){
			props.close();
		}
    }

	return (
        <Modal
            aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			disableBackdropClick={true}
            open={props.isOpen}
            onClose={close}
        >
            <div id="faces_input" className="modal modalMedium">
                <div className="modalRoot" style={Tools.getModalRootStyle()}>
					{props.close ? (
						<ModalHeader close={props.close}/>
					):(
						<ModalHeader/>
					)}
                    <Paper className="paper modalPaper">
	                    <Title text="サーバー応答なし" size={2}/>
    	                <div style={{marginBottom:"4em"}}>
							<p>
								サーバーからの応答がありませんでした。<br/>
								回線の混雑もしくはサーバーの動作に異常が発生している可能性があります。<br/>
								しばらく待ってから再度お試しください。
							</p>
							<p>
	                        	また、<a href="https://twitter.com/retroink_dev" target="_blank" rel="noopener noreferrer" className="link">公式ツイッター</a>の情報もご確認ください。
							</p>
							{props.close &&
							<div style={{marginTop:"2em",textAlign:"center"}}>
								<Button variant="outlined" color="primary" onClick={props.close}>再読込</Button>
							</div>
							}
                    	</div>
					</Paper>
                </div>
            </div>
        </Modal>

    );
}

export default NoResponse;
