import React from 'react';
import ImgFace from './ImgFace';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkNight = (props:Props)=> {
    return (
		<React.Fragment>
			<div className="sysTalk">
				<span className="type">おやすみ</span>
				{props.talk.Day===0 &&
				<span className="detail">
					ハコブネが動き出しました。
				</span>
				}
				{props.talk.Day>0 &&
				<span className="detail">
					残り物たちはそれぞれの場所で眠りにつきました。
				</span>
				}
				<span className="time">{Tools.toTime(props.talk.Date)}</span>
			</div>
			<div className="talk talkPublic">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
				<div className="text">
					<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
					<div>
						夜が来ます…<br/>
						どこまでも逃げるように眠りなさい、急いで…<br/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default TalkNight;
