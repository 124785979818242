import React from 'react';
import ImgFace from './ImgFace';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkEntry = (props:Props)=> {
    return (
		<React.Fragment>
			<div className="talk talkPublic">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
				<div className="text">
					<h3>{props.room.Faces[Inc.numFaces+1].Name}</h3>
					<div>
						{props.room.Faces[props.talk.CharaId-1].Name}{props.talk.Message}
					</div>
				</div>
			</div>

			<div className="sysTalk">
				<span className="type">エントリ</span>
				<span className="detail">{props.room.Faces[props.talk.CharaId-1].Name}が{props.room.Faces[Inc.numFaces+1].Name}に名を告げました</span>
				<span className="time">{Tools.toTime(props.talk.Date)}</span>
			</div>
		</React.Fragment>
	);
}

export default TalkEntry;
