import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
    text:string,
    second?:string,
    icon?:"user-circle",
    size?:number,
}

const Title:React.FC<Props> = (props)=> {
    let fontsize = {fontSize:"170%"};
    //let iconsize = {marginRight:"0.25em",fontSize:"120%"};
    if( props.size ){
        if( props.size===2 ){
            fontsize = {fontSize:"150%"};
            //iconsize = {marginRight:"0.25em",fontSize:"106%"};
        }
        if( props.size===3 ){
            fontsize = {fontSize:"120%"};
            //iconsize = {marginRight:"0.25em",fontSize:"85%"};
        }
    }
	return (
        <div className="paperTitle">
            <div style={fontsize}>
                {props.icon &&
                    <FontAwesomeIcon icon={props.icon} style={{marginRight:"0.5em"}}/>
                }
                {props.text}
                {props.second &&
                <span>{props.second}</span>
                }
            </div>
        </div>
    );
}

export default Title;
