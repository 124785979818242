import React,{useEffect,useState,useMemo} from 'react';
//import {Link,Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
//import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
//import Tooltip from '@material-ui/core/Tooltip';
//import AutorenewIcon from '@material-ui/icons/Autorenew';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalHeader from './ModalHeader';
//import ColInput from './ColInput';
//import ClimbUp from './ClimbUp';
import Loading from './Loading';
import ImgFace from './ImgFace';
//import FaceConfirm from './FaceConfirm';
//import store from './store';
//import MultiLines from './MultiLines';
//import Toast from './Toast';
//import {UserContainer} from './UserContainer';
import {Inc,Room,PrivateData,MemberOpened} from './Inc';
import {Tools} from './Tools';
//import Yesno from './Yesno';
import Ajax from './Ajax';
//import store from './store';

interface Props {
	room:Room,
	data:PrivateData,
	isOpen: boolean,
	day: number,
	userId: string,
	close(day:number):void,
}

const MorningNews = (props:Props)=> {
    const [states,setStates] = useState({
		isLoading:true,
		result:"",
		message: "",
		members: [] as MemberOpened[],
		change: false,
		day: 0,
	})
	//const ref = useRef();
	const ref = React.createRef<HTMLDivElement>()
	//const userState = UserContainer.useContainer();
	const load = async ()=>{
		//let userId = "";
		//if(userState.user){
		//	userId = userState.user.uid;
		//}
		//Ajax.setToken(userState.token);
		const res = await Ajax.getMorning(props.room.Id,props.userId,props.day);
		//console.log("getMorning",res);
		if( res.ok ){
			setStates({...states,isLoading:false,message:res.message,day:res.day,change:res.change,members:res.members});
		}
	}
	const close = ()=>{
		//console.log("close",states.day);
		props.close(states.day);
	}
	const seeResult = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.result ){
            return;
		}
		setStates({...states,result:event.currentTarget.dataset.result});
		up();
	}
	const closeLabel = useMemo( ()=>{
		if( !states.change ){
			return "もどる";
		} else {
			return "新しい一日へ";
		}
	},[props.day,states.day])
	const isBlessed = useMemo( ()=>{
		if( !props.data.History || !props.data.History[props.day-1] ){
			return false;
		}
		return props.data.History[props.day-1].Blessed;
	},[props.day,props.data])
	const up = ()=>{
		//window.scrollTo(0,0);
		if( ref && ref.current ){
			ref.current.scrollTop = 0;
		}
	}
	const news = useMemo( ()=>{
		if(states.day>1){
			let alives:{ [key: string]: number[]; } = {};
			let placeIds = [] as string[];
			let emptys = [] as number[];
			let trapId = "";
			let deads = [] as number[];
			let result = "";
			let isSafe = false;
			const lines = states.message.split(/\n/);
			for( let i=0; i<lines.length; i++ ){
				const sp = lines[i].trim().split("_");
				if( sp.length===2 ){
					if( sp[0]==="trap" ){
						if( !placeIds.includes(sp[1]) ){
							placeIds.push(sp[1]);
						}
						trapId = sp[1];
					} else if( sp[0]==="result" ){
						result = sp[1];
					} else if( sp[0]==="empty" ){
						emptys.push(parseInt(sp[1]))
					} else if( sp[1].trim()==="v" ){
						deads.push( parseInt(sp[0]) );
					} else {
						if( sp[1]==="S" ){
							isSafe = true;
						}
						if( alives[ sp[1] ] ){
							alives[ sp[1] ].push(parseInt(sp[0]));
						} else {
							alives[ sp[1] ] = [parseInt(sp[0])];
						}
						if( !placeIds.includes(sp[1]) ){
							placeIds.push(sp[1]);
						}
					}
				}
			}
			let sizeDeadSm:3|4|6|12 = 6;
			let sizeDeadXs:6|12 = 6;
			if( deads.length===1 ){
				sizeDeadSm = 12;
				sizeDeadXs = 12;
			} else if( deads.length===2 ){
				sizeDeadSm = 6;
			} else if( deads.length===3 ){
				sizeDeadSm = 4;
			} else if( deads.length>3 ){
				sizeDeadSm = 3;
			}


			return (
				<div id="morning_news">
					<h2>{states.day}日目の朝</h2>
					<div className="section center">
						彼らはそれぞれの場所で目を開けた<br/>
						{trapId!=="" &&
						<div>{Inc.placeNames[parseInt(trapId)]}がやられた！と誰かが叫んだ</div>
						}
						{isSafe &&
						<div style={{color:"#FF0000"}}>（深刻なバグの回避のため緊急措置として全員をセーフルームに保護しました）</div>
						}
					</div>
					<div className="morningAlives">
						<Grid container spacing={1}>
						{placeIds.sort().map((placeId:string,index1:number)=>{
							const key1 = "place" + index1;
							let name = "セーフルーム";
							if( placeId!=="S" ){
								name = Inc.placeNames[parseInt(placeId)];
							}
							let sizeSmall1:6|12 = 6;
							let sizeSmall2:3|4|6|12 = 6;
							let sizeXs2:6|12 = 6;
							if( placeId==="0" || placeId==="S" ){
								sizeSmall1 = 12;
								sizeSmall2 = 3;
							}
							if( alives[placeId] ){
								if( alives[placeId].length===1 ){
									sizeSmall2 = 12;
									sizeXs2 = 12;
								} else if( alives[placeId].length===2 ){
									sizeSmall2 = 6;
								} else if( alives[placeId].length===3 ){
									sizeSmall2 = 4;
								} else if( alives[placeId].length>3 ){
									sizeSmall2 = 3;
								}
							}
							return (
						<Grid item xs={12} sm={sizeSmall1} key={key1}><div className="place">
							<div className="placeName">
								{name}
							</div>
							{trapId!==placeId ? (
							<Grid container spacing={0}>
								{alives[placeId].map((charaId:number,index2:number)=>{
									const key2 = "name"+index2;
									return (
								<Grid item xs={sizeXs2} sm={sizeSmall2} key={key2}>
									<div className="chara">
										<ImgFace code={props.room.CharaSetId} charaId={charaId}/>
										<div className="name">{props.room.Faces[ charaId-1 ].Name}</div>
									</div>
								</Grid>
									)
								})}
							</Grid>
							):(
							<div className="trap">
								<FontAwesomeIcon icon="dove"/>
								<p>鳩罠</p>
							</div>
							)}
						</div></Grid>
							)
						})}
						</Grid>
					</div>
					{emptys.length>0 &&
					<div className="morningEmptys">
						<div className="count">{emptys.length}個の寝床が立入禁止になった</div>
						{emptys.map((id:number,index:number)=>{
							const key = "empty"+index;
							return (
								<p key={key}>{Inc.placeNames[id]}</p>
							)
						})}
					</div>
					}
					{deads.length>0 ? (
					<div className="morningDeads">
						<div className="count">
							{!isBlessed ? (
							<span>そして姿の見えなくなった者が{deads.length}人…</span>
							):(
							<span>あなたは{deads.length}人の仲間を消滅させた</span>
							)}
						</div>
						<Grid container spacing={0}>
						{deads.map((charaId:number,index:number)=>{
							const key = "dead"+index;
							return (
								<Grid item xs={sizeDeadXs} sm={sizeDeadSm} key={key}>
									<div className="dead">
										<ImgFace code={props.room.CharaSetId} charaId={charaId} dead/>
										<div className="name">{props.room.Faces[ charaId-1 ].Name}</div>
									</div>
								</Grid>
							)
						})}
						</Grid>
					</div>
					):(
					<div className="noDead">
						誰一人欠けることなく新しい一日が始まった
					</div>
					)}
					{props.room.Day===states.day && props.room.Status!==Inc.ROOM_STATUS_INSERVICE ? (
					<div className="goNext">
						<Button variant="contained" color="primary" onClick={seeResult} data-result={result} style={{minWidth:"80%",fontSize:"140%"}}>結果発表</Button>
					</div>
					):(
					<div className="goNext">
						<Button variant="contained" color="primary" onClick={close} style={{minWidth:"80%"}}>{closeLabel}</Button>
					</div>
					)}
				</div>
			)
		} else {
			if( states.message==="" ){
				return (null);
			}
			const ids = states.message.split("\n");
			return (
				<div id="morning_news">
					<h2>新しい朝</h2>
					<div className="section center">
						{ids.length}人はホールで顔を合わせました。<br/>
						{props.room.Faces[Inc.numFaces].Name}と{props.room.Faces[Inc.numFaces+1].Name}は嬉しそうです。<br/>
					</div>
					<div className="first">
						<Grid container spacing={3}>
						{ids.map((id:string,index:number)=>{
							const key = "face"+index;
							const charaId = parseInt(id);
							return (
								<Grid item xs={6} sm={4} md={3} key={key}>
								<div className="center">
									<ImgFace code={props.room.CharaSetId} charaId={charaId}/>
									<div className="name">{props.room.Faces[ charaId-1 ].Name}</div>
								</div>
								</Grid>
							)
						})}
						</Grid>
					</div>
					{false &&
					<div className="morningDeads">
						<div className="dead">
							<ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/>
							<div className="name">{props.room.Faces[ Inc.numFaces ].Name}</div>
						</div>						
					</div>
					}				
					<div className="goNext">
						<Button variant="contained" color="primary" onClick={close} style={{minWidth:"80%"}}>{closeLabel}</Button>
					</div>
				</div>
			)
		}
	},[states.message,states.day]);

	const result = useMemo( ()=>{
		if( states.result==="" ){
			return (null);
		}
		let label = "もどる";
		if(states.change){
			label = "エピローグへ";
		}
		return (
			<div id="morning_news">
				{states.result==="draw" &&
				<h2 className="resultDraw">ドロー</h2>
				}
				{states.result==="ghost" &&
				<h2 className="resultGhost">ゴーストたちの勝利</h2>
				}
				{states.result!=="draw" && states.result!=="ghost" && states.result!=="" &&
				<h2 className="resultWin">{props.room.Faces[parseInt(states.result)-1].Name}の勝利</h2>
				}
				<div className="memberResultList">
					<Grid container spacing={3}>
				{states.members.map((member:MemberOpened,index:number)=>{
					const key = "member"+index;
					return (
						<Grid item xs={12} sm={6} className="member" key={key}>
						<div className="member">
							<div className="face">
								<ImgFace code={props.room.CharaSetId} charaId={member.CharaId}/>
							</div>
							<div className="name">
								{props.room.Faces[member.CharaId-1].Name}
							</div>
							<div className="item">
								
							</div>
							<div className="result">
								<p className="item">- {Inc.itemNames[parseInt(member.ItemId)-1]} -</p>
							{member.VanishedDay>0 && member.WinnerId===0 &&
							<React.Fragment>
								<p className="trap">{member.VanishedDay}日目に鳩罠で消滅</p>
								<p className="icon iconTrap"><FontAwesomeIcon icon="dove"/></p>
							</React.Fragment>
							}
							{member.VanishedDay>0 && member.WinnerId>0 &&
							<React.Fragment>
								<p className="ghost">
									{member.VanishedDay}日目に消滅<br/>
									<span>VS {props.room.Faces[member.WinnerId-1].Name}</span>
								</p>
								<p className="icon iconGhost"><FontAwesomeIcon icon="ghost"/></p>
							</React.Fragment>
							}
							{member.VanishedDay===0 &&
								<p className="alive">生存</p>
							}
							</div>
						</div>
						</Grid>
					)
				})}
					</Grid>
				</div>
				<div className="roomTimeline">
					<div className="resultMessage">
						{states.result==="draw" &&
						<p>
							立入禁止だらけのハコブネを眺め、<br/>
							ソニアはこれ以上の歴史を諦めた。<br/>
						</p>
						}
						{states.result==="ghost" &&
						<p>
							そして誰もいなくなった。<br/>
						</p>
						}
						{states.result!=="draw" && states.result!=="ghost" && states.result!=="" &&
						<p>
							そして{props.room.Faces[parseInt(states.result)-1].Name}が残った。
						</p>
						}
					</div>
					<div className="goDown">
						<FontAwesomeIcon icon="arrow-down"/>
					</div>
					<div className="talks">
						<div className="talk talkPublic">
							<div className="serial">
								<span className="date"></span>
							</div>
							<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
							<div className="text">
								<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
								{states.result==="draw" &&
								<div>
									またやり直せば良いのです…<br/>
									手垢のついた世界を何度でも…<br/>
								</div>
								}
								{states.result==="ghost" &&
								<div>
									新しい世界には何も必要ありません…<br/>
									どうぞ安らかな滅亡を…<br/>
								</div>
								}
								{states.result!=="draw" && states.result!=="ghost" && states.result!=="" &&
								<div>
									私たちの役目はここで終わりです…<br/>
									{props.room.Faces[parseInt(states.result)-1].Name}様、世界を貴方に託します…<br/>
								</div>
								}
							</div>
						</div>					
						<div className="talk talkPublic">
							<div className="serial">
								<span className="date"></span>
							</div>
							<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
							<div className="text">
								<h3>{props.room.Faces[Inc.numFaces+1].Name}</h3>
								{states.result==="draw" &&
								<div>
									「わかりましたか？下着がパッツンパッツンだったって言うんですよ。」
								</div>
								}
								{states.result==="ghost" &&
								<div>
									「本当に律儀な牛は夜のうちに黙って肉になるものです。」
								</div>
								}
								{states.result!=="draw" && states.result!=="ghost" && states.result!=="" &&
								<div>
									「はじめまして、{props.room.Faces[parseInt(states.result)-1].Name}様、ええ、いつだって初めましてなんですよ。」<br/>
								</div>
								}
							</div>
						</div>					

					</div>
					</div>
				<div className="goNext">
					<Button variant="contained" color="primary" onClick={close} style={{minWidth:"80%"}}>{label}</Button>
				</div>
			</div>
		)
	},[states.result]);

    useEffect( ()=>{
		setStates({
			isLoading:true,
			result:"",
			message: "",
			members: [] as MemberOpened[],
			change: false,
			day: 0,
		})
		if( props.isOpen ){
			load();
		}
	},[props.isOpen]);

	//if(!userState.ready){
	//	return (null);
	//}

    return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			disableBackdropClick={true}
			open={props.isOpen}
			onClose={close}
		>
			<div id="face_choice" className="modal">
				<ModalHeader/>
				<div className="modalRoot" style={Tools.getModalRootStyle()} ref={ref}>
					<Paper className="paper modalPaper">
						{states.result!=="" ? (
							<React.Fragment>{result}</React.Fragment>
						):(
							<React.Fragment>{news}</React.Fragment>
						)}
					</Paper>
					<Loading open={states.isLoading}/>
				</div>
			</div>
		</Modal>
    );
}

export default MorningNews;
