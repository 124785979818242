import React,{useEffect,useState,useMemo} from 'react';
import {Redirect,useParams} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import PlayRoomTalks from './PlayRoomTalks';
import MemberList from './MemberList';
import MorningNews from './MorningNews';
import RoomStatus from './RoomStatus';
import Help from './Help';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Toast from './Toast';
import Ajax from './Ajax';
import store from './store';
import TopBar from "./TopBar";
import NoResponse from './NoResponse';
//import {UserContainer} from './UserContainer';
import {Inc,Member} from './Inc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
}

const ViewRoom = (props:Props)=> {
    const [states,setStates] = useState({
		room:Inc.defaultRoom(),
		day: 0,
		newDay: 0,
		members: [] as Member[],
		disableds: [] as string[],
		myData: Inc.defaultPrivateData(),
		message: "",
		limit: "",
		pause: false,
		isLoading:false,
		isFaceChoice:false,
		isMorningNews:false,
		isMorningCall:false,
		serverError:false,
		ready:false,
		isMenu:false,
	})
	const [goTop,setGoTop] = useState(false);
	const [toast,setToast] = useState("");
	//const [serverError,setServerError] = useState(false);
	//const userState = UserContainer.useContainer();
	const {roomId} = useParams();
	
	const closeToast = ()=>{
		setToast("");
	}
    const loadRoom = async ()=>{
    	if(!roomId){
    		return;
    	}
    	try {
			setStates({...states,isLoading: true});

			//const room = await store.getRoom(roomId);
			const res0 = await Ajax.getRoom(roomId);
			const room = res0.room;
			const res = await Ajax.getAllMembers(roomId,room.Day)

			if( room.Status===Inc.ROOM_STATUS_WAITING || room.Status===Inc.ROOM_STATUS_INSERVICE || room.Status===Inc.ROOM_STATUS_EPILOGUE ){
				setGoTop(true);
			} else if( res.ok ){
				setStates({...states,ready:true,room:room,day:room.Day,members:res.members,isLoading: false,serverError:false});
			} else {
				errorServer();	
			}
		} catch(error){
			errorServer();
		}
	}
	const openMorningNews = (day:number)=>{
		setStates({...states,pause:true,isMorningCall:false,isMorningNews:true,newDay:day});
	}
	const closeMorningNews = async (day:number)=>{
		//const day = states.myData.CheckedDay+1;
		//console.log("closeMorning",day);
		try {
			if( states.myData.IsPlay && states.myData.CheckedDay<day ){
				if(!roomId){
					return;
				}
				const res = await Ajax.getMembers(roomId,"");
				setStates({...states,day:res.day,members:res.members,myData:res.myData,limit:res.limit,disableds:res.disableds,newDay:0,pause:false,isMorningNews:false});
			} else {
				setStates({...states,day:day,newDay:0,pause:false,isMorningNews:false});
			}
		} catch(error){
			errorServer();
		}
	}
	const changeDay = (event: React.MouseEvent<HTMLElement>)=>{
        if( !event.currentTarget.dataset.day ){
            return;
		}
		const day = parseInt(event.currentTarget.dataset.day);
		setStates({...states,day:day});
	}
	const errorServer = ()=>{
		setStates({...states,pause:true,serverError:true});
	}
	const retryServer = async ()=>{
		await loadRoom();
	}
	const buttonDays = useMemo( ()=>{
		let arr = [];
		for( let i=0; i<=states.room.Day; i++ ){
			arr.push(i);
		}
		return (
			<Grid container spacing={1}>
			{arr.map((day:number,index:number)=>{
			const key = "day"+index;
			let label = day + "日";
			if( day===0 ){
				label = "プロローグ";
			} else if( states.room.Day===day ){
				label = "エピローグ";
			}
			return (
				<Grid item xs={4} sm={2} key={key}>
					<Button key={key} size="small" variant="text" color="default" onClick={changeDay} data-day={day} disabled={day===states.day} style={{width:"100%"}}>{label}</Button>
				</Grid>
			);})}
			</Grid>
		)
	},[states.room.Day,states.day,changeDay]);

	const side = useMemo(()=>{
		return (
		<div className="roomData">
			<RoomStatus room={states.room} limit={states.limit}/>
			<MemberList room={states.room} members={states.members}/>
		</div>
		)
	},[states.room,states.members,states.limit]);
	const openMenu = ()=>{
		setStates({...states,isMenu:true});
	}
	const closeMenu = ()=>{
		setStates({...states,isMenu:false});
	}
	const updateDay = ()=>{

	}
	const openMorningCall = ()=>{

	}

    useEffect( ()=>{
		//console.log("effect",userState.user);
		const start = async ()=>{
			await loadRoom();
		}
		start();
	},[]);


	if( !roomId || goTop ){
		return <Redirect to="/"/>
	}

	let userId = "";

    return (
		<React.Fragment>
		<TopBar menu={openMenu}/>
        <div id="main" className="mediumRoot">
			{states.ready &&
			<React.Fragment>
			<Paper className="paper">
				<Grid container spacing={4}>
					<Hidden implementation="js" smDown>
						<Grid item xs={12} md={3}>
							{side}
						</Grid>
					</Hidden>
					<Grid item xs={12} md={9} style={{padding:"1px",paddingBottom:"2px"}}>
						<div className="roomTimeline">
							<div className="roomDays">
								{buttonDays}
							</div>
							<PlayRoomTalks room={states.room} userId={userId} data={states.myData} day={states.day} refresh={updateDay} pause={states.pause} call={openMorningCall} news={openMorningNews} error={errorServer}/>
						</div>
					</Grid>
				</Grid>
			</Paper>
            <ClimbUp/>
			<Help/>
			<MorningNews isOpen={states.isMorningNews} room={states.room} userId={userId} day={states.newDay} data={states.myData} close={closeMorningNews}/>
			</React.Fragment>
			}
			<Loading open={states.isLoading || !states.ready}/>
			<Toast close={closeToast} mes={toast}/>
			<NoResponse isOpen={states.serverError} close={retryServer}/>
			<Drawer anchor="left" open={states.isMenu} onClose={closeMenu}>
				<Box className="drawer">
					<Box style={{textAlign:"right"}}>
						<IconButton size="small" onClick={closeMenu}><FontAwesomeIcon icon="arrow-left"/></IconButton>
					</Box>
					{side}
				</Box>
			</Drawer>

        </div>
		</React.Fragment>
    );
}

export default ViewRoom;
