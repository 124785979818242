import React from 'react';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';
import ImgFace from './ImgFace';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkTimeout = (props:Props)=> {
    return (
    	<React.Fragment>
		<div className="sysTalk">
			<span className="type">時間切れ</span>
			<span className="detail">このルームは閉鎖されました</span>
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
		<div className="talk talkPublic">
			<div className="serial">
				<span className="date">{Tools.toDate(props.talk.Date)}</span>
			</div>
			<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
			<div className="text">
				<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
				<div>
					さようなら、何者にもなれなかった残り物の皆様…<br/>
					ここではないどこかで再開しましょう…<br/>
				</div>
			</div>
		</div>
		<div className="talk talkPublic">
			<div className="serial">
				<span className="date">{Tools.toDate(props.talk.Date)}</span>
			</div>
			<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+1}/></div>
			<div className="text">
				<h3>{props.room.Faces[Inc.numFaces].Name}</h3>
				<div>
					時間です…
				</div>
			</div>
		</div>

		</React.Fragment>
	);
}

export default TalkTimeout;
