import React from 'react';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkNext = (props:Props)=> {
    return (
		<div className="sysTalk">
			<span className="type">寝床</span>
			{props.talk.Message !== "" ? (
				<span className="detail">{props.room.Faces[props.talk.CharaId-1].Name}が{Inc.placeNames[parseInt(props.talk.Message)]}を選択しました</span>
			):(
				<span className="detail">{props.room.Faces[props.talk.CharaId-1].Name}が■■■を選択しました</span>
			)}
			<span className="time">{Tools.toTime(props.talk.Date)}</span>
		</div>
	);
}

export default TalkNext;
