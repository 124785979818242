import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import ModalHeader from './ModalHeader';
//import useStyles from './Styles';
import Title from './Title';
import {Tools} from './Tools';

interface Props {
    latest:string,
    isOpen:boolean,
}

const OldVersion:React.FC<Props> = (props)=> {
    //const classes = useStyles();
    if( !props.isOpen ){
        return (null);
	}
	
	const close = ()=>{

	}

	return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
            <div id="faces_input" className="modal modalMedium">
                <div className="modalRoot" style={Tools.getModalRootStyle()}>
					<ModalHeader/>
                    <Paper className="paper modalPaper">
	                    <Title text="最新バージョンにアップデートして下さい" size={2}/>
    	                <Grid container spacing={2} style={{marginBottom:"1em"}}>
							<Grid item xs={12} sm={6}><div className="verBox">
								<h3>ご利用中のバージョン</h3>
								<div className="current">
									{process.env.REACT_APP_VERSION}
								</div>
							</div></Grid>
							<Grid item xs={12} sm={6}><div className="verBox">
								<h3>最新バージョン</h3>
								<div className="latest">
									{props.latest}
								</div>
							</div></Grid>
						</Grid>
						<div className="verGuide">
							<h3>スマホ（iOS/Android）の場合</h3>
							<div>
								「画面を上から下へスワイプして放す」を何度か行うことで更新されます。
								<p>
								※ブラウザをすべて閉じる、キャッシュを削除する等の作業が必要になる場合もあります。<br/>
								</p>
							</div>
						</div>
						<div className="verGuide">
							<h3>アプリの場合</h3>
							<div>
								「ホーム画面に追加」してアプリとして起動している場合は、アプリを終了してからもう一度起動すると最新バージョンが読み込まれます。
								<p>
								※２～３回繰り返さないと更新されない場合もあります。<br/>
								</p>
							</div>
						</div>
						<div className="verGuide">
							<h3>Windowsの場合</h3>
							<div>
								強制リロード（Ctrl+F5）で最新バージョンが読み込まれます。<br/>
								<p>
								※ブラウザをすべて閉じる、キャッシュを削除する等の作業が必要になる場合もあります。<br/>
								</p>
							</div>
						</div>
						<div className="verGuide">
							<h3>Macの場合</h3>
							<div>
								強制リロード（command+R 又は command+Shift+R）で最新バージョンが読み込まれます。<br/>
								<p>
								※ブラウザをすべて閉じる、キャッシュを削除する等の作業が必要になる場合もあります。<br/>
								</p>
							</div>
						</div>
					</Paper>
                </div>
            </div>
        </Modal>

    );
}

export default OldVersion;
