import React from 'react';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import ModalHeader from './ModalHeader';
//import useStyles from './Styles';
import Title from './Title';
import {Tools} from './Tools';

interface Props {
    isOpen:boolean,
}

const NoService:React.FC<Props> = (props)=> {
    //const classes = useStyles();
    if( !props.isOpen ){
        return (null);
    }

    const close = ()=>{

    }

	return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={close}
        >
            <div id="faces_input" className="modal modalMedium">
                <div className="modalRoot" style={Tools.getModalRootStyle()}>
					<ModalHeader/>
                    <Paper className="paper modalPaper">
	                    <Title text="休止中" size={2}/>
    	                <div style={{marginBottom:"4em"}}>
        	                メンテナンスのためサービスを一時休止しています。<br/>
            	            最新情報は<a href="https://twitter.com/retroink_dev/" className="link">公式ツイッター</a>をご確認ください。<br/>
                	    </div>
					</Paper>
                </div>
            </div>
        </Modal>

    );
}

export default NoService;
