import React from 'react';
import Autolinker from 'autolinker';

interface Props {
    text: string,
    name?: string,
    autolink?: boolean,
}

const MultiLines = (props:Props)=> {
    if(!props.text){
        return (null);
    }

    let name = "line";
    if( props.name ){
        name = props.name;
    }
    let text = props.text.replace(/\r\n/g,"\n");
    text = text.replace(/\r/g,"\n");

    const tmp = text.split("\n").map((line:string,index:number)=>{
        const key = name+index;
        if( line==="" ){
            line = "　";
        }
        if( props.autolink ){
            line = Autolinker.link(line);
        }
        return (
            <div key={key} dangerouslySetInnerHTML={{__html : line}}></div>
        )
    });

    return (
        <React.Fragment>
            {tmp}
        </React.Fragment>
    )
}

export default MultiLines;