import React from 'react';
import Modal from '@material-ui/core/Modal';
import Calendar from 'react-calendar';
import {Tools} from './Tools';
import ModalHeader from './ModalHeader';
//import useStyles from './Styles';


interface Props {
    close():void,
    change(dt:string):void,
    value:string,
    isOpen:boolean,
}

const DatePick = (props:Props)=> {
    //const classes = useStyles();

    const changeDate = (date:Date | Date[])=>{
        const dt = date.toLocaleString().split(" ");
        //console.log(dt[0]);
        props.change(dt[0]);
    }

    let date:Date = new Date();
    if( props.value ){
        if(props.value.match(/^\d{4}[-/]\d{1,2}[-/]\d{1,2}$/)){
            const sp = props.value.split(/[-/]/);
            if( parseInt(sp[0])>2000 && parseInt(sp[0])<2100 && parseInt(sp[1])>0 && parseInt(sp[1])<13 && parseInt(sp[2])>0 && parseInt(sp[2])<32 ){
                date = new Date(props.value);
            }
        }
    }

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
            onClose={props.close}
        >
            <div style={Tools.getModalRootStyle()} className="calendar">
                <ModalHeader close={props.close}/>
                <div className="dialogRoot">
                    <Calendar onChange={changeDate} value={date}/>
                </div>
            </div>
        </Modal>
    );
}

export default DatePick;
