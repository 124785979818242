import React,{useEffect,useState,useRef} from 'react';
import {Link,Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ModalHeader from './ModalHeader';
import ColInput from './ColInput';
//import ClimbUp from './ClimbUp';
import Loading from './Loading';
import ImgFace from './ImgFace';
//import store from './store';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import {Inc,Face,CharaSet,Dataset} from './Inc';
import {Tools} from './Tools';
import Ajax from './Ajax';
import store from './store';

interface Props {
	isOpen: boolean,
	add(roomName:string,charaSetId:string,intervalId:string):void,
	close():void,
}

const RoomInput = (props:Props)=> {
    const [states,setStates] = useState({
		charaSetId: "",
		intervalId: "",
		roomName: "",
		charaSets:[] as Dataset[],
        isLoading:true,
	})
	const [seeId,setSeeId] = useState("");
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();

	const closeToast = ()=>{
		setToast("");
	}

	const change = (name:string,value:string)=>{
		setStates({...states,[name]:value});
	}
	const validate = ()=>{
		if( states.roomName.trim()==="" ){
			setToast("名前を入力してください");
			return;
		} else if( states.roomName.length>30 ){
			setToast("名前は30文字以内にしてください")
			return;
		}
		if( states.charaSetId==="" ){
			setToast("世界線を選んでください");
			return;
		}
		if( states.intervalId==="" ){
			setToast("ペースを選んでください");
			return;
		}

		props.add(states.roomName,states.charaSetId,states.intervalId);
		
	}
	const load = async ()=>{
		if(!userState.user){
			return;
		}
		try {
			setStates({...states,charaSetId:"",isLoading:true})
			let list:Dataset[] = [];
			const sets1 = await store.getMyCharaSets(userState.user.uid);
			for(let i=0; i<sets1.length; i++ ){
				if( sets1[i].Permission!=="0" ){
					list.push({
						id: sets1[i].Id,
						label: sets1[i].Title,
					})
				}
			}
			const sets2 = await store.getPublicCharaSets();
			for(let i=0; i<sets2.length; i++ ){
				if( sets2[i].UserId!==userState.user.uid){
					list.push({
						id: sets2[i].Id,
						label: sets2[i].Title,
					})
				}
			}
			setStates({...states,charaSets:list,charaSetId:"",isLoading:false})
		} catch(error){
			console.log("error",error);

		}
	}

    useEffect( ()=>{
        if( !userState.ready || !userState.user ){
			return;
		}
		if( props.isOpen ){
			load();
		}
	},[userState.ready,userState.user,props.isOpen]);

    if( !userState.ready ){
        return (null);
    }

    return (
		<Modal
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			onClose={props.close}
		>
			<div id="faces_input" className="modal">
				<ModalHeader close={props.close}/>
				<div className="modalRoot" style={Tools.getModalRootStyle()}>
					<Paper className="paper modalPaper">
						<h2>
							ハコブネ作成
							<div className="btns">
								<Button variant="contained" color="primary" onClick={validate}>作成する</Button>
							</div>
						</h2>
						<Grid container spacing={2} className="faces">
							<Grid item xs={2} className="formName">
								ハコブネの名前
							</Grid>
							<Grid item xs={10}>
								<ColInput type="text" id="roomName" value={states.roomName} name="ハコブネの名前" placeholder="30文字以内" change={change} noName={true} dense={true}/>
							</Grid>
							<Grid item xs={2} className="formName">
								世界線
							</Grid>
							<Grid item xs={10}>
								<ColInput type="select" list={states.charaSets} id="charaSetId" value={states.charaSetId} name="世界線" change={change} noName={true} dense={true}/>
							</Grid>
							<Grid item xs={2} className="formName">
								進行ペース
							</Grid>
							<Grid item xs={10}>
								<ColInput type="select" list={Inc.dayIntervals} id="intervalId"value={states.intervalId} name="ペース" change={change} noName={true} dense={true}/>
							</Grid>
						</Grid>
						<div className="notice">
							<h3>注意事項</h3>
							<div>
								・ハコブネの削除はできません。進行ペースを短めに設定する場合は参加者を集められるかどうかを事前によくご検討ください。<br/>
								・自分の作ったハコブネでまだ終了していないものがある場合は、新たにハコブネを作ることはできません。<br/>
							</div>
						</div>
					</Paper>
					<Loading open={states.isLoading}/>
					<Toast close={closeToast} mes={toast}/>
				</div>

			</div>
		</Modal>
    );
}

export default RoomInput;
