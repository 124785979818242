import React from 'react';
import ImgFace from './ImgFace';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkClosed = (props:Props)=> {
    return (
		<React.Fragment>
			<div className="sysTalk">
				<span className="type">終幕</span>
				<span className="detail">お疲れ様でした。気を付けてお帰り下さい</span>
				<span className="time">{Tools.toTime(props.talk.Date)}</span>
			</div>
			<div className="talk talkPublic">
				<div className="serial">
					<span className="date">{Tools.toDate(props.talk.Date)}</span>
				</div>
				<div className="face"><ImgFace code={props.room.CharaSetId} charaId={Inc.numFaces+2}/></div>
				<div className="text">
					<h3>{props.room.Faces[Inc.numFaces+1].Name}</h3>
					<div>
						「おじい様！おじい様！ちょっと星をどけてくれませんこと？」
					</div>
				</div>
			</div>
		</React.Fragment>
);
}

export default TalkClosed;
