import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
	open:boolean,
}

const Loading = (props:Props)=> {
	return (
		<Backdrop open={props.open} style={{zIndex:9999,color:"#FFFFFF"}}>
			<CircularProgress color="inherit" />
		</Backdrop>
    );
}

export default Loading;
