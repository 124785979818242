import React,{useMemo} from 'react';
import ImgFace from './ImgFace';
import MultiLines from './MultiLines';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Inc,Room,Talk} from './Inc';
import {Tools} from './Tools';

interface Props {
	room:Room,
	talk:Talk,
}

const TalkPrivate = (props:Props)=> {
    return (
		<div className="talk talkPublic">
			<div className="serial">
				<span className="date">{Tools.toDate(props.talk.Date)}</span>
				<span className="no">No.{props.talk.Serial}</span>
			</div>
			<div className="face"><ImgFace code={props.room.CharaSetId} charaId={props.talk.CharaId}/></div>
			<div className="text textPrivate">
				<h3><FontAwesomeIcon icon="volume-mute" className="iconPrivate"/> {props.room.Faces[props.talk.CharaId-1].Name}</h3>
				<MultiLines text={props.talk.Message}/>
			</div>
		</div>
	);
}

export default TalkPrivate;
