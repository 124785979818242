import React,{useEffect,useState,useRef,useCallback} from 'react';
import {Link,Redirect,useParams} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RoomInput from './RoomInput';
import FaceChoice from './FaceChoice';
import ImgFace from './ImgFace';
import ColInput from './ColInput';
import ClimbUp from './ClimbUp';
import MultiLines from './MultiLines';
import TinyLoading from './TinyLoading';
import Yesno from './Yesno';
//import store from './store';
import Toast from './Toast';
import {Tools} from './Tools';
import {UserContainer} from './UserContainer';
import {Inc,Dataset,Room,Member} from './Inc';
import Ajax from './Ajax';
import store from './store';

interface Props {
	room:Room,
	data:Member,
	disableds:string[],
	call(day:number):void,
	update(data:Member):void,
}

const PlayRoomNext = (props:Props)=> {
	const [states,setStates] = useState({
		list:[] as Dataset[],
		selectId: "",
		mes: "",
		isLoading: false,
	})
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();
	const closeToast = ()=>{
		setToast("");
	}
	const change = (name:string,value:string)=>{
		//setStates({...states,[name]:value});
		setStates({...states,[name]:value,mes:"今晩の寝床は「"+Inc.placeNames[parseInt(value)]+"」で良い？"});
	}
	//const openConfirm = ()=>{
	//	setStates({...states,mes:"今晩の寝床は「"+Inc.placeNames[parseInt(states.selectId)]+"」で良い？"});
	//}
	//const closeConfirm = ()=>{
	//	setStates({...states,mes:""});
	//}
	const no = ()=>{
		setStates({...states,mes:"",selectId:""});
	}
	const yes = async ()=>{
		if(!userState.user){
			return;
		}
		setStates({...states,mes:"",isLoading:true})
		try {
			Ajax.setToken(userState.token);
			const res = await Ajax.setNext(props.room.Id,props.room.Day,userState.user.uid,states.selectId);
			//console.log(res);
			setStates({...states,mes:"",selectId:"",isLoading:false});
			if( res.ok ){
				props.update({...props.data,SelectId:states.selectId});
			} else {
				if( res.reason==="anotherday" ){
					props.call( props.room.Day+1 );
				} else {
					setToast("エラー");
				}
			}
		} catch(error) {
			setStates({...states,mes:"",isLoading:false});
			setToast("エラー");
		}
	}
    useEffect( ()=>{
    	setStates({
    		list: Tools.arrayToList(Inc.placeNames),
    		selectId: "",
			mes: "",
			isLoading: false,
    	})
	},[]);

	//console.log("next",props);
	if( props.data.CharaId===0 || props.room.Day===0 || props.room.Status !== Inc.ROOM_STATUS_INSERVICE ){
		return (null);
	}

    return (
		<div id="play_room_next" className="next">
			<h2>
				{props.data.SelectId==="" &&
					<FontAwesomeIcon icon="exclamation-triangle" className="notice"/>
				}
				今晩の寝床
			</h2>
			{states.isLoading && 
			<TinyLoading isLoading={true}/>
			}
			{!states.isLoading && props.data.SelectId==="" &&
			<FormControl fullWidth={true} error={true}>
				<ColInput type="select" list={states.list} id="selectId" value={states.selectId} dense={true} change={change} name="" noName={true} disabledIds={props.disableds}/>
			</FormControl>
			}
			{!states.isLoading && props.data.SelectId!=="" &&
			<div className="selectedNext">
				<FontAwesomeIcon icon="bed"/> {Inc.placeNames[parseInt(props.data.SelectId)]}
			</div>
			}
			<Yesno yes={yes} no={no} mes={states.mes} isOpen={states.mes!==""}/>
			<Toast close={closeToast} mes={toast}/>
        </div>
    );
}

export default PlayRoomNext;
