import React from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
//import Hidden from '@material-ui/core/Hidden';
//import logo from "./imgs/logo.png";
import Tooltip from '@material-ui/core/Tooltip';

import namelogo from "./img/logo.png";
//import back from "./imgs/back.png";

import HomeIcon from '@material-ui/icons/Home';

//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
	//isTop:boolean,
}

const Logo = (props:Props)=> {
	return (
		<Link to="/" style={{textDecoration:"none"}}>
			<Tooltip title="トップに戻る"><img src={namelogo} alt="title" style={{width:"140px",height:"32px",marginTop:"3px"}}/></Tooltip>
		</Link>
	)
}

export default Logo;