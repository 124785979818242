import React from 'react';

interface Props {
    code: string,
    charaId: number,
    updateKey?:string,
	shadow?: boolean,
	private?: boolean,
	dead?: boolean,
    clickable?: boolean,
}

const ImgFace = (props:Props)=> {
	if( props.code===""){
		const style1 = {
			margin: "0 auto",
			width: "100px",
			height: "133px",
			textAlign: "center",
			position: "relative",
			backgroundColor: "#EEEEEE",
		} as React.CSSProperties;
		const style2 = {
			position: "absolute",
			top: "50%",
			left: "50%",
			transform:"translate(-50%, -50%)",
			color: "#999999",
		} as React.CSSProperties;
		return (
			<div style={style1}><span style={style2}>No.{props.charaId} </span></div>
			)
	}

	const path=process.env.REACT_APP_FACE_URL + props.code + ".png?u=" + props.updateKey;
	let pos = (props.charaId-1) * 100;
	if( pos<0 ){
		pos = 0;
	}
	let opacity = "1.0";
	let cursor = "default";
	if( props.shadow ){
		opacity = "0.1";
	}
	if( props.dead ){
		opacity = "0.5";
	}
	if( props.private ){
		opacity = "0.6";
	}
	if( props.clickable ){
		cursor = "pointer";
	}
	const style = {
		margin: "0 auto",
		width: "100px",
		height: "133px",
		backgroundImage:`url(${path})`,
		backgroundPosition:`-${pos}px 0`,
		opacity: `${opacity}`,
		cursor: `${cursor}`,
	} as React.CSSProperties;
	const cl = "face" + props.charaId;
    return (
    	<div style={style} className={cl}></div>
    )
}

export default ImgFace;